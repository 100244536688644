import { ref, readonly } from "vue";
import { defineStore } from "pinia";
import {Backend} from "~/components/API/Backend";
import type {HelpPage} from "~/types/help";

export const useHelpStore = defineStore("help-store", () => {
  const links = ref({});
  const meta = ref({});

  const helpPages = ref<Array<HelpPage>>([]);
  const helpPage = ref<HelpPage>({} as HelpPage);

  const searchQuery = ref<string>('');
  const setSearchQuery = (value: string) => {
    searchQuery.value = value;
  };

  // function index(params: any) {
  //   return Backend.index('/posts', params)
  //       .then((data) => {
  //         articles.value = data.data
  //         links.value = data.links
  //         meta.value = data.meta
  //       })
  // }

  function show(id: number) {
    return Backend.get('/help', String(id))
        .then((data) => {
          helpPage.value = data.data
        })
  }

  function showSlug(slug: string) {
    return Backend.get('/help', slug)
        .then((data) => {
          helpPage.value = data.data
        })
  }

  // function authorShow(id: number) {
  //   return Backend.get('/authors', String(id))
  //       .then((data) => {
  //         author.value = data.data
  //       })
  // }
  //
  // function authorShowSlug(slug: string) {
  //   return Backend.get('/authors', slug)
  //       .then((data) => {
  //         author.value = data.data
  //       })
  // }

  return {
    searchQuery: readonly(searchQuery),
    setSearchQuery,

    meta,
    links,

    helpPages,
    helpPage,

    // index,
    show,
    showSlug,
  };
});