import Wrapper from './Wrapper.vue';

import GetAsset from  './GetAsset/index.vue';
import GetAssetQA from  './GetAssetQA/index.vue';
import BecomePartner from  './BecomePartner.vue';
import ContactUs from  './ContactUs.vue';
import RequestDemo from  './RequestDemo.vue';
import RequestDemoQA from  './RequestDemoQA.vue';
import RequestDemoVSA from  './RequestDemoVSA.vue';
import RequestDemoShort from  './RequestDemoShort.vue';
import GetFreeDemo from './GetFreeDemo.vue';
import RequestBundle from './RequestBundle.vue';
import GDPR from './GDPR/index.vue';

export default {
  Wrapper,

  GetAsset,
  GetAssetQA,
  BecomePartner,
  ContactUs,
  RequestDemo,
  RequestDemoQA,
  RequestDemoVSA,
  RequestDemoShort,
  GetFreeDemo,
  RequestBundle,
  GDPR,
}
