<template>
  <component :is="whitepaper === 'long' && !short ? 'Long' : 'Short'" :to="computedTo" />
</template>

<script setup lang="ts">
import useLocalePdfPath from "~/composables/useLocalePdfPath";

import Long from './Long.vue';
import Short from './Short.vue';

defineOptions({
  components: {
    Long,
    Short,
  },
});

const props = withDefaults(
  defineProps<{
    to?: string;
    short: boolean;
  }>(),
  {
    short: false,
  }
);

const { query: { whitepaper } } = useRoute();

const localePdfPath = useLocalePdfPath();

const computedTo = computed(() => props.to || localePdfPath("DOCUMENT_3_REASONS"));
</script>
