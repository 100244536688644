<template>
  <div data-component-name="TheSearchItem">
    <NuxtLink
      v-html="title"
      :to="link"
      class="title"
      target="_blank"
      @click="$emit('opened')"
    />

    <div class="content">
      <time :datetime="item.publish_at">
        {{ date }}
      </time>

      <template v-if="date && content.length">...</template>

      <div v-html="content" class="markup" />
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from 'moment';

// composables
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// utils
import truncate from 'lodash.truncate';
import { getArticleLink } from '~/utils/getArticleLink';

// types
import type { SearchItem } from '~/types/search';

const DEFAULT_TRUNCATE_SETTINGS = {
  length: 60,
  separator: /,? +/,
  omission: '...',
};

const props = defineProps<{ item: SearchItem }>();
defineEmits<{ opened: [] }>();

const localePathWithSlash = useLocalePathWithSlash();

const link = computed(() =>
  localePathWithSlash(
    getArticleLink({
      type: props.item.type,
      slug: props.item.slug,
      categories: [{ slug: props.item.category_slug }],
    }),
  ),
);
const title = computed(() =>
  truncate(props.item.title, DEFAULT_TRUNCATE_SETTINGS),
);
const date = computed(() =>
  moment(props.item.publish_at).format('MMM D, YYYY'),
);
const content = computed(() =>
  truncate(props.item.content, { ...DEFAULT_TRUNCATE_SETTINGS, length: 145 }),
);
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/typography";
@import "$/functions/token";

[data-component-name="TheSearchItem"] {
  @include flex-start-start;
  flex-direction: column;

  a.title {
    display: block;

    @include link-text-2;
    color: token("link");
    margin-bottom: 0.5rem;

    transition: background-color 0.15s ease;

    &:hover {
      background-color: rgba(token("link"), 16%);
    }
  }

  &:deep(.content) {
    @include body-3;
    color: token("text-primary");

    time {
      color: token("text-secondary");
    }

    .markup {
      display: inline;
    }
  }
}
</style>
