const isProdEnv = process.env.NUXT_SITE_ENV === 'production';
const DEV_ONLY_LOCALES = [];

export const I18N_LOCALES = [
  { i18nKey: 'languages.english',     code: 'en', iso: 'en-US', file: 'en.json' },
  { i18nKey: 'languages.german',      code: 'de', iso: 'de-DE', file: 'de.json' },
  { i18nKey: 'languages.french',      code: 'fr', iso: 'fr-FR', file: 'fr.json' },
  { i18nKey: 'languages.spanish',     code: 'es', iso: 'es-ES', file: 'es.json' },
  { i18nKey: 'languages.italian',     code: 'it', iso: 'it-IT', file: 'it.json' },
  { i18nKey: 'languages.portuguese',  code: 'pt', iso: 'pt-BR', file: 'pt-BR.json' }
].filter(
  ({ code }) => {
    if (!isProdEnv) return true;

    return !DEV_ONLY_LOCALES.includes(code);
  }
);

export const SUPPORTED_LOCALES = I18N_LOCALES.map(({ code }) => code);
export const UNSUPPORTED_LOCALES = isProdEnv ? DEV_ONLY_LOCALES : [];
