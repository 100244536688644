
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de",
  "fr",
  "es",
  "it",
  "pt"
]

export const localeLoaders = {
  "en": [{ key: "../translations/en.json", load: () => import("../translations/en.json" /* webpackChunkName: "locale__app_translations_en_json" */), cache: true }],
  "de": [{ key: "../translations/de.json", load: () => import("../translations/de.json" /* webpackChunkName: "locale__app_translations_de_json" */), cache: true }],
  "fr": [{ key: "../translations/fr.json", load: () => import("../translations/fr.json" /* webpackChunkName: "locale__app_translations_fr_json" */), cache: true }],
  "es": [{ key: "../translations/es.json", load: () => import("../translations/es.json" /* webpackChunkName: "locale__app_translations_es_json" */), cache: true }],
  "it": [{ key: "../translations/it.json", load: () => import("../translations/it.json" /* webpackChunkName: "locale__app_translations_it_json" */), cache: true }],
  "pt": [{ key: "../translations/pt-BR.json", load: () => import("../translations/pt-BR.json" /* webpackChunkName: "locale__app_translations_pt_BR_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "i18nKey": "languages.english",
      "code": "en",
      "language": "en-US",
      "files": [
        "/app/translations/en.json"
      ]
    },
    {
      "i18nKey": "languages.german",
      "code": "de",
      "language": "de-DE",
      "files": [
        "/app/translations/de.json"
      ]
    },
    {
      "i18nKey": "languages.french",
      "code": "fr",
      "language": "fr-FR",
      "files": [
        "/app/translations/fr.json"
      ]
    },
    {
      "i18nKey": "languages.spanish",
      "code": "es",
      "language": "es-ES",
      "files": [
        "/app/translations/es.json"
      ]
    },
    {
      "i18nKey": "languages.italian",
      "code": "it",
      "language": "it-IT",
      "files": [
        "/app/translations/it.json"
      ]
    },
    {
      "i18nKey": "languages.portuguese",
      "code": "pt",
      "language": "pt-BR",
      "files": [
        "/app/translations/pt-BR.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "translations",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "locale-01",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "all",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "i18nKey": "languages.english",
    "code": "en",
    "language": "en-US",
    "files": [
      {
        "path": "/app/translations/en.json"
      }
    ]
  },
  {
    "i18nKey": "languages.german",
    "code": "de",
    "language": "de-DE",
    "files": [
      {
        "path": "/app/translations/de.json"
      }
    ]
  },
  {
    "i18nKey": "languages.french",
    "code": "fr",
    "language": "fr-FR",
    "files": [
      {
        "path": "/app/translations/fr.json"
      }
    ]
  },
  {
    "i18nKey": "languages.spanish",
    "code": "es",
    "language": "es-ES",
    "files": [
      {
        "path": "/app/translations/es.json"
      }
    ]
  },
  {
    "i18nKey": "languages.italian",
    "code": "it",
    "language": "it-IT",
    "files": [
      {
        "path": "/app/translations/it.json"
      }
    ]
  },
  {
    "i18nKey": "languages.portuguese",
    "code": "pt",
    "language": "pt-BR",
    "files": [
      {
        "path": "/app/translations/pt-BR.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
