<template>
  <div data-component-name="AppRadioGroup">
    <strong v-html="label" />

    <ul>
      <li v-for="option of options" :key="option">
        <AppRadio
          v-model="modelValue"
          :value="option"
          name="role"
          :id="id + '_' + option"
        />

        <label :for="id + '_' + option">
          {{ option.toLowerCase() }}
        </label>
      </li>
    </ul>

    <span
      v-if="error"
      v-html="error"
      class="error"
    />
  </div>
</template>

<script lang="ts" setup>
import AppRadio from '~/components/redesign/AppRadio.vue';

defineProps<{
  label: string
  options: readonly string[]
  id: string
  name: string

  error?: string
}>()

const modelValue = defineModel<string>()
</script>

<style lang="scss" scoped>
@import "$/functions/token";
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/mixins/media";

[data-component-name="AppRadioGroup"] {
  padding: 0 1rem;

  strong {
    @include subtitle-3;

    display: block;
    margin-bottom: 0.5rem;
  }

  ul {
    @include flex-start-start;
    gap: 0.94rem;

    li {
      @include flex-center-start;
      gap: 0.13rem;

      label {
        cursor: pointer;
        @include body-3;
        text-transform: capitalize;
      }
    }
  }

  span.error {
    @include caption;
    color: token('error');
    margin-top: 0.25rem;
    display: block;
  }
}
</style>