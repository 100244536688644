export const PATHS = {
  CONTACTS_US:               { path: '/contacts/',                                               i18nKey: 'Contact_Us'                   },
  BLOG:                      { path: '/blog/',                                                   i18nKey: 'Blog'                         },
  TECHNICAL:                 { path: '/technical/',                                              i18nKey: 'Technical'                    },
  BUSINESS:                  { path: '/business/',                                               i18nKey: 'Business'                     },
  GUIDES:                    { path: '/guides/',                                                 i18nKey: 'Storage_Guides'               },
  SUPPORT:                   { path: '/support/',                                                i18nKey: 'Support'                      },
  PRODUCT:                   { path: '/object-storage/',                                         i18nKey: 'Product'                      },
  RESOURCES:                 { path: '/resources/',                                              i18nKey: 'Resources'                    },
  ABOUT:                     { path: '/about/',                                                  i18nKey: 'About_Us'                     },
  ABOUT_COMPANY:             { path: '/about/',                                                  i18nKey: 'Company'                      },
  PARTNERS:                  { path: '/partners/',                                               i18nKey: 'Partners'                     },
  CAREERS:                   { path: '/careers/',                                                i18nKey: 'Careers'                      },
  HELP:                      { path: '/help/introduction/',                                      i18nKey: 'Help_Center'                  },
  HELP_NEW:                  { path: '/help-new/',                                               i18nKey: 'Help_Center'                  },
  INSTALLATION:              { path: '/installation/',                                           i18nKey: 'Installation'                 },
  REQUEST_DEMO:              { path: '/request-live-demo/',                                      i18nKey: 'Request_a_demo'               },
  ANALYST_REPORTS:           { path: '/resources/analyst-reports/',                              i18nKey: 'Analyst_Reports'              },
  LEGAL:                     { path: '/legal/',                                                  i18nKey: 'Legal'                        },
  TRANSLATION_DISCLAIMER:    { path: '/legal/machine-translation-disclaimer/',                   i18nKey: 'Translation_disclaimer'       },
  PARTNER_REWARDS_TOS:       { path: '/legal/partner-rewards-terms-and-conditions/',             i18nKey: 'Terms_&_Conditions'           },
  WHITE_PAPERS:              { path: '/resources/white-papers/',                                 i18nKey: 'White_Papers'                 },
  TECHNICAL_PAPERS:          { path: '/resources/technical-papers/',                             i18nKey: 'Solution_Briefs'              },
  CASE_STUDIES:              { path: '/resources/case-studies/',                                 i18nKey: 'Case_Studies'                 },
  NEWSROOM:                  { path: '/newsroom/',                                               i18nKey: 'Newsroom'                     },
  IN_THE_NEWS:               { path: '/newsroom/in-the-news/',                                   i18nKey: 'In_the_News'                  },
  PRESS_RELEASES:            { path: '/newsroom/press-releases/',                                i18nKey: 'Press_Releases'               },
  MEDIA_KIT:                 { path: '/newsroom/media-kit/',                                     i18nKey: 'Media_Kit'                    },
  EVENTS:                    { path: '/events/',                                                 i18nKey: 'Events'                       },
  PRODUCT_REVIEWS:           { path: '/newsroom/product-reviews/',                               i18nKey: 'Product_Reviews'              },
  PRIVACY_POLICY:            { path: '/legal/privacy-policy',                                    i18nKey: 'Privacy_Notice'               },
  VULNERABILITY_POLICY:      { path: '/legal/vulnerability-disclosure-policy/',                  i18nKey: 'Vulnerability_Policy'         },
  JOIN_US_AT_VEEAMON_2024:   { path: '/join-us-at-veeamon-2024/',                                i18nKey: 'Object_First_at_VeeamON_2024' },
  VSA_POLICY:                { path: '/legal/object-first-virtual-storage-appliance-agreement/', i18nKey: 'Terms_&_Conditions_VSA'       },
  ACES_PROGRAM:              { path: '/aces-program',                                            i18nKey: 'Aces'                         },
  TRUST_CENTER:              { path: '/trust-center',                                            i18nKey: 'Trust_Center'                 },
  BACKUP_STORAGE_CALCULATOR: { path: '/backup-storage-calculator',                               i18nKey: 'Backup_Storage_Calculator'    },
} as const;
