import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';

// constants
import { HELP, REGEX } from '~/constants'

// types
import { 
  PostStatus, 
  type Data, 
  type HelpNestedTreeNode, 
  type HelpVersion 
} from '~/types';
import { HELP_PAGES_THREE, HELP_VERSIONS } from '~/constants/api-endpoints';

export const useHelpPageStore = defineStore('help-page', () => {
  const route = useRoute();
  const { query: { preview } } = route;
  const { public: { api_v2: baseURL } } = useRuntimeConfig();

  const versions = ref<HelpVersion[]>([]);
  const treeData = ref<HelpNestedTreeNode[]>([]);

  const withDrafts = computed(() => preview === 'true');

  const currentVersion = computed(() =>
    REGEX.HELP_VERSION.test(route.params.version)
      ? route.params.version
      : HELP.LATEST_VERSION
  );

  const versionId = computed(() => versions.value.find(item => item.version === currentVersion.value)?.id);

  const fetchVersions = async () => {
    try {
      const response = await $fetch<Data<HelpVersion[]>>(HELP_VERSIONS, {
        baseURL,
        params: { 
          order_column: 'version',
          order: 'DESC',
          per_page: 50,
        },
      });
  
      versions.value = response.data
    } catch (error) {
      console.error(`Error fetching help-versions: ${error}`);
    }
  };

  const fetchTreeData = async () => {
    try {
      const response = await $fetch<HelpNestedTreeNode[]>(HELP_PAGES_THREE, {
        baseURL,
        params: { 
          version_id: versionId.value,
          'statuses[]': withDrafts.value
            ? [PostStatus.DRAFT, PostStatus.PUBLISHED]
            : [],
        },
      });

      treeData.value = response ?? [];
    } catch (error) {
      console.error(`Error fetching help-tree-data: ${error}`);
    }
  };

  watch(
    () => route.params.version,
    (newVersion, oldVersion) => {
      if (newVersion && newVersion !== oldVersion) {
        fetchTreeData();
      }
    },
  );

  onMounted(async () => {
    await fetchVersions();
    fetchTreeData();
  })

  return {
    versions,
    versionId,
    treeData,

    withDrafts,

    fetchTreeData,
  };
});
