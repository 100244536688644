<template>
  <div :class="{ resizing: isResizing }" class="wrapper" @mouseup="endResize">
    <header>
      <button
        :class="{ opened: isAsideOpened }"
        class="aside"
        @click="toggleAside(!isAsideOpened)"
      >
        <span />
        <span />
        <span />
      </button>

      <NuxtLink :to="localePath('/')" class="logo">
        <SvgLogoHelp />
      </NuxtLink>

      <div class="right">
        <HelpVersionSwitcher :options="filteredVersionsOptions"/>

        <nav :class="{ opened: isMenuOpened }">
          <ul class="row-1">
            <li v-for="{ path, i18nKey } of MENU_ROW_1" :key="path">
              <NuxtLink :to="localePathWithSlash(path)">
                {{ $t(i18nKey) }}
              </NuxtLink>
            </li>
          </ul>

          <ul class="row-2">
            <li v-for="{ path, i18nKey } of MENU_ROW_2" :key="path">
              <NuxtLink :to="localePathWithSlash(path)">
                {{ $t(i18nKey) }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>

      <button
        :class="{ opened: isMenuOpened }"
        class="menu"
        @click="toggleMenu(!isMenuOpened)"
      >
        <span />
        <span />
        <span />
      </button>
    </header>

    <aside
      :style="{ width: `${asideWidth}px` }"
      :class="{ opened: isAsideOpened }"
    >
      <ul class="tabs">
        <li
          v-for="(tab, label) of tabs"
          :key="label"
          :class="{ active: tab.active }"
          class="tab"
        >
          <button @click="onTabClick(tab)">
            <component :is="tab.icon" />
            <span>{{ label }}</span>
          </button>
        </li>
      </ul>

      <ul class="tabs-content">
        <li :class="{ active: tabs.Contents.active }" class="tab-content">
          <nav v-if="treeData && treeData.length">
            <HelpNavItemNew
              v-for="node of treeData"
              :key="node.id"
              :node
              @click="setActiveSlug"
            />
          </nav>
        </li>

        <li :class="{ active: tabs.Search.active }" class="tab-content">
          <input
            v-model="query.query"
            type="search"
            placeholder="Search table of contents..."
          >

          <div v-if="searchItems.length" class="has-results">
            <ul class="search-items">
              <li v-for="item of searchItems" :key="item.id">
                <NuxtLink
                  :to="
                    localePathWithSlash(
                      getResultPageURL(item.version_version, item.slug)
                    )
                  "
                >
                  {{ truncate(item.title, DEFAULT_TRUNCATE_SETTINGS) }}
                </NuxtLink>

                <p>
                  {{
                    truncate(item.content, {
                      ...DEFAULT_TRUNCATE_SETTINGS,
                      length: 145,
                    })
                  }}
                </p>
              </li>
            </ul>

            <AppPagination
              v-if="total > query.size"
              v-model="page"
              :total-items="total"
              :items-per-page="query.size"
              :max-pages-shown="2"
            />
          </div>
        </li>
      </ul>
    </aside>

    <hr @mousedown="startResize" >

    <main :style="{ width: mainWidth }">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
// TODO: open navItems leads to active item
// TODO: handle search request error

// components
import HelpNavItemNew from '~/components/HelpNavItemNew.vue';
import AppPagination from '~/components/redesign/AppPagination.vue';
import HelpVersionSwitcher from '~/components/redesign/HelpVersionSwitcher.vue';

// composables
import { useLocalePath } from '#i18n';

// utils
import debounce from 'lodash.debounce';
import getValidRange from '~/utils/getValidRange';
import truncate from 'lodash.truncate';

// store
import useUIStore from '~/stores/ui';
import { useHelpPageStore } from '~/stores/help-new';

// constants
import { HELP_PAGES_SEARCH } from '~/constants/api-endpoints';
import { PATHS } from '~/constants/nav-items';

// types
import type { Search } from '~/types/search';
import type { HelpSearchQuery } from '~/types/help.js';
import { type UnwrapRef } from 'vue';
import { PostStatus } from '~/types';
import type { HelpVersion } from '~/types';

const DEFAULT_TRUNCATE_SETTINGS = {
  length: 60,
  separator: /,? +/,
  omission: '...',
};

const { setLoading } = useUIStore();
const localePath = useLocalePath();
const localePathWithSlash = useLocalePathWithSlash();

const {
  public: { api_v2: baseURL },
} = useRuntimeConfig();

const MENU_ROW_1 = [PATHS.CONTACTS_US, PATHS.BLOG, PATHS.SUPPORT];

const MENU_ROW_2 = [
  PATHS.PRODUCT,
  PATHS.RESOURCES,
  PATHS.NEWSROOM,
  PATHS.ABOUT,
  PATHS.PARTNERS,
  PATHS.CAREERS,
];

const RESIZER_WIDTH = 8;

const isResizing = ref(false);
const asideWidth = ref(400);
const mainWidth = computed(
  () => `calc(100% - ${asideWidth.value}px - ${RESIZER_WIDTH}px)`,
);

const resizeHandler = ({ clientX }: MouseEvent) => {
  asideWidth.value = getValidRange(clientX, 600, 250);
};
const startResize = () => {
  isResizing.value = true;
  document.addEventListener('mousemove', resizeHandler, { passive: true });
};
const endResize = () => {
  isResizing.value = false;
  document.removeEventListener('mousemove', resizeHandler);
};

type Keys = 'Contents' | 'Search';
type Icons = 'SvgContents' | 'SvgSearch';
type Tab = {
  [key in Keys]: {
    active: boolean;
    icon: Icons;
  };
};

const tabs = reactive<Tab>({
  Contents: {
    active: true,
    icon: 'SvgContents',
  },
  Search: {
    active: false,
    icon: 'SvgSearch',
  },
});

const onTabClick = (tab: UnwrapRef<Tab[Keys]>) => {
  for (const key in tabs) {
    const currentTab = tabs[key as Keys];

    currentTab.active = currentTab === tab;
  }
};

const activeSlug = ref<null | string>(null);
const setActiveSlug = (slug: string) => {
  activeSlug.value = slug;
  toggleAside(false);
};

const isAsideOpened = ref(false);
const toggleAside = (value: boolean) => {
  isAsideOpened.value = value;
};

const isMenuOpened = ref(false);
const toggleMenu = (value: boolean) => {
  isMenuOpened.value = value;
};

const helpStore = useHelpPageStore();
const { treeData, versions, withDrafts } =
  storeToRefs(helpStore);

// Search
const query = reactive<HelpSearchQuery>({
  query: '',
  from: 0,
  size: 10,
});

const resetSearchPagination = () => {
  query.from = 0;
};

const {
  status: searchStatus,
  data: searchData,
  refresh: refreshSearchData,
} = await useAsyncData(
  'search',
  () => $fetch<Search>(HELP_PAGES_SEARCH, { baseURL, query }),
  {
    immediate: false,
    server: false,
  },
);

watch(
  () => query.query,
  debounce(() => {
    resetSearchPagination();
    refreshSearchData();
  }, 500),
);

watch(
  () => query.from,
  () => refreshSearchData(),
);

watchEffect(() => setLoading(searchStatus.value === 'pending'));

const searchItems = computed(() => searchData.value?.data ?? []);
const total = computed(() => searchData.value?.meta.total || 0);
const page = computed({
  get: () => Math.ceil(query.from / query.size) + 1,
  set: value => {
    query.from = query.size * (value - 1);
  },
});

const getResultPageURL = (version: string, slug: string) =>
  `${PATHS.HELP_NEW.path}${version}/${slug}`;

const buildOptionUrl = (version: HelpVersion) => {
  const previewParam = version.status === PostStatus.DRAFT ? '?preview=true' : '';
  return `${PATHS.HELP_NEW.path}${version.version}/${previewParam}`;
}

const filteredVersionsOptions = computed(() =>
  versions.value
    .filter(version =>
      withDrafts.value
        ? version.status === PostStatus.DRAFT ||
          version.status === PostStatus.PUBLISHED
        : version.status === PostStatus.PUBLISHED,
    )
    .map(version => {
      return {
        to: buildOptionUrl(version),
        label: version.version,
      }
    }),
);

useHead({
  meta: [{ name: 'robots', content: 'noindex, nofollow' }],
});
</script>

<style scoped lang="scss">
@use "_/mixins/flex";
@use "_/mixins/media";
@use "_/variables/_color";
@use "_/variables/_font";
@use "_/fn";

$phone: 76rem;

.wrapper {
  @include flex.start-sb;
  flex-wrap: wrap;

  width: 100vw;
  height: 100vh;

  overflow: hidden;

  @include media.tablet {
    position: relative;
  }

  &.resizing {
    header,
    aside,
    main {
      user-select: none;
    }
  }

  header {
    @include flex.center-sb;

    width: 100%;

    padding: 0 3.125rem;
    border-bottom: 1px solid color.$cool-gray;

    @include media.tablet {
      position: relative;
      padding: 0.9375rem 1.5625rem;
    }

    button.aside {
      display: none;

      @include media.tablet {
        display: block;
        position: relative;

        width: 2.625rem;
        height: 2.125rem;

        border-radius: 4px;
        border: 1px solid transparent;

        transition: all 0.15s ease;

        &:hover {
          background-color: color.$light-gray;
          border: 1px solid color.$off-white;
        }

        span {
          display: block;
          position: absolute;
          left: 0.5625rem;

          width: 1.375rem;
          height: 0.125rem;

          background-color: color.$deep-purple;

          transform-origin: center center;
          transition: all 0.15s ease;

          &:nth-child(1) {
            top: 0.5rem;
            transform: rotate(0);
            transition: transform 0.2s ease, top 0.2s 0.2s ease;
          }

          &:nth-child(2) {
            transform: translateY(-50%);
            opacity: 1;
          }

          &:nth-child(3) {
            bottom: 0.5rem;
            transform: rotate(0);
            transition: transform 0.2s ease, bottom 0.2s 0.2s ease;
          }
        }

        &.opened {
          background-color: color.$light-gray;
          border: 1px solid color.$medium-gray;

          span {
            left: 0.75rem;

            width: 0.9375rem;
            height: 0.25rem;

            background-color: color.$dark-gray;

            &:nth-child(1) {
              top: 0.875rem;
              transform: rotate(45deg);
              transition: top 0.2s ease, transform 0.2s 0.2s ease;
            }

            &:nth-child(2) {
              opacity: 0;
              transition: opacity 0.2s ease;
            }

            &:nth-child(3) {
              bottom: 14px;
              transform: rotate(-45deg);
              transition: bottom 0.2s ease, transform 0.2s 0.2s ease;
            }
          }
        }
      }
    }

    .logo {
      svg {
        width: 17.5rem;
      }
    }

    .right {
      @include flex.center-sb;

      gap: 1rem;

      [data-component-name="AppDropdown"] {
        min-width: 6rem;
        width: 8rem;
      }

      nav {
        @include media.tablet {
          height: 0;
          overflow: hidden;

          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          z-index: 2;

          background-color: fn.token('surface');
          box-shadow: 0 10px 10px color.$deep-navy;

          transition: all 0.2s ease;

          &.opened {
            height: 20.625rem;
          }
        }

        ul {
          display: flex;
          align-items: center;

          @include media.tablet {
            flex-direction: column;
          }

          li {
            a {
              display: block;
              text-decoration: none;
              font-family: font.$inter;

              color: fn.token('surf-cont-dark');
              text-shadow: 0 0 1px transparent;
              transition: color 0.3s ease;

              &:hover {
                color: color.$deep-purple;
                text-shadow: 0 0 1px color.$deep-purple;
              }
            }
          }

          &.row-1 {
            justify-content: flex-end;

            li {
              a {
                font-size: 0.875rem;
                padding: 0.5rem 0.625rem;

                @include media.tablet {
                  padding-left: 0;
                  padding-right: 0;
                }
              }

              &:first-of-type {
                a {
                  padding-left: 0;

                  @include media.tablet {
                    margin-top: 0.25rem;
                  }
                }
              }

              &:last-of-type {
                a {
                  padding-right: 0;

                  @include media.tablet {
                    margin-bottom: 0.25rem;
                  }
                }
              }
            }
          }

          &.row-2 {
            justify-content: space-between;
            border-top: 2px solid fn.token('surf-cont-dark');

            li {
              $offset-x: 0.9375rem;

              &:first-of-type {
                a {
                  padding-left: 0;

                  &::after {
                    left: 0;
                  }
                }
              }

              &:last-of-type {
                a {
                  padding-right: 0;

                  &::after {
                    right: 0;
                  }
                }
              }

              a {
                position: relative;
                padding: 0.5rem $offset-x;

                @include media.tablet {
                  padding-left: 0;
                  padding-right: 0;
                }

                &:after {
                  content: "";
                  display: block;

                  position: absolute;
                  bottom: -1px;
                  left: $offset-x;
                  right: $offset-x;

                  height: 0.125rem;
                  background-color: transparent;
                  transition: all 0.15s ease;

                  @include media.tablet {
                    left: 0;
                    right: 0;
                  }
                }

                &:hover {
                  &:after {
                    background-color: color.$deep-purple;
                  }
                }
              }
            }
          }
        }
      }
    }

    button.menu {
      display: none;

      @include media.tablet {
        display: block;
        position: relative;
        z-index: 1;

        width: 2.5rem;
        height: 1.875rem;

        span {
          display: block;
          position: absolute;

          transform-origin: center center;

          width: 2.5rem;
          height: 0.125rem;

          background-color: color.$deep-purple;

          &:nth-child(1) {
            top: 0.25rem;
            transform: rotate(0);
            transition: transform 0.2s ease, top 0.2s 0.2s ease;
          }

          &:nth-child(2) {
            transform: translateY(-1px);
            opacity: 1;
          }

          &:nth-child(3) {
            bottom: 0.25rem;
            transform: rotate(0);
            transition: transform 0.2s ease, bottom 0.2s 0.2s ease;
          }
        }

        &.opened {
          span {
            &:nth-child(1) {
              top: 0.875rem;
              transform: rotate(-45deg);
              transition: top 0.2s ease, transform 0.2s 0.2s ease;
            }

            &:nth-child(2) {
              opacity: 0;
              transition: opacity 0.2s ease;
            }

            &:nth-child(3) {
              bottom: 0.875rem;
              transform: rotate(45deg);
              transition: bottom 0.2s ease, transform 0.2s 0.2s ease;
            }
          }
        }
      }
    }
  }

  aside,
  main {
    height: calc(100% - 4.5rem);
  }

  aside {
    display: flex;
    flex-direction: column;

    background-color: color.$off-white;

    @include media.tablet {
      position: absolute;
      top: 4.6875rem;
      left: 0;
      right: 0;

      z-index: 1;

      width: 100% !important;

      transform: translateX(-100%);
      transition: all 0.2s ease;

      &.opened {
        transform: translateX(0);
      }
    }

    ul.tabs {
      @include flex.center-sb;

      li.tab {
        width: 50%;

        &.active {
          button {
            background-color: color.$off-white;

            svg {
              fill: color.$deep-purple;
            }

            span {
              color: color.$deep-purple;
            }

            &:before {
              width: 100%;
            }
          }
        }

        button {
          width: 100%;
          height: 100%;

          padding: 0.8125rem;

          cursor: pointer;

          @include flex.start-center;

          background-color: color.$soft-gray;

          position: relative;

          svg {
            width: 0.9375rem;
            height: 0.9375rem;

            margin-bottom: 0.5625rem;

            transition: fill 0.15s ease;
          }

          span {
            font-size: 0.875rem;
            font-weight: 700;
            color: color.$cool-gray;

            transition: color 0.15s ease;
          }

          &:before {
            content: "";
            display: block;

            position: absolute;
            top: 0;
            width: 0;
            height: 0.1875rem;

            background-color: color.$deep-purple;

            transition: width 0.3s ease;
          }

          &:hover {
            span {
              color: color.$deep-purple;
            }

            &:before {
              width: 100%;
            }
          }
        }
      }
    }

    ul.tabs-content {
      overflow-y: auto;

      li.tab-content {
        display: none;

        padding: 0.9375rem 1.5625rem;

        &.active {
          display: block;
        }

        input[type="search"] {
          height: 2.125rem;
          padding: 0.375rem 0.75rem;
          font-size: 0.875rem;
          color: color.$midnight-gray;
          border: 1px solid color.$light-silver;
          border-radius: 4px;
          transition: border-color 0.15s ease;

          width: 100%;

          &:focus {
            border-color: color.$deep-purple;
          }
        }

        ul.search-items {
          padding: 1rem 0;

          li {
            padding: 0.5rem 0;

            a {
              display: block;
              text-decoration: none;
              font-family: font.$inter;

              color: fn.token('text-primary');
              text-shadow: 0 0 1px transparent;
              transition: color 0.3s ease;

              &:hover {
                color: color.$deep-purple;
                text-shadow: 0 0 1px color.$deep-purple;
              }
            }

            p {
              padding: 1rem 0;

              font-family: font.$inter;
              font-size: 0.875rem;
              font-weight: 400;
            }
          }
        }

        nav {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  hr {
    margin: 0;
    margin-block: 0;
    border: unset;

    display: block;

    height: 100%;
    width: 0.5rem;

    background-color: color.$soft-gray;

    cursor: w-resize;

    position: relative;

    @include media.tablet {
      display: none;
    }

    &:after {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOS45NiAxMjIuODgiPjxwYXRoIGZpbGw9IiM5MDkyOTQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xhc3M9ImNscy0xIiBkPSJNMTUsMEExNSwxNSwwLDEsMSwwLDE1LDE1LDE1LDAsMCwxLDE1LDBabTAsOTIuOTNhMTUsMTUsMCwxLDEtMTUsMTUsMTUsMTUsMCwwLDEsMTUtMTVabTAtNDYuNDdhMTUsMTUsMCwxLDEtMTUsMTUsMTUsMTUsMCwwLDEsMTUtMTVaIj48L3BhdGg+PC9zdmc+);
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      width: 0.375rem;
    }
  }

  main {
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
    background-color: fn.token('surface');
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    @include media.tablet {
      width: 100% !important;
    }

    h1 {
      font-family: font.$inter;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.75rem;
      color: fn.token('surf-cont-dark');
    }
  }

  ul {
    margin: 0;
  }
}
</style>
