type Fields = Record<string, string[]>

export default class FormValidationError extends Error {
  public code: number;
  public fields: Fields;

  constructor(code: number, fields: Fields) {
    super('Server validation error');

    this.code = code;
    this.fields = fields;
  }
}
