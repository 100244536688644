<template>
  <div
    v-on-click-outside="clickOutsideHandler"
    data-component-name="LanguageSwitcherDesktop"
  >
    <TheHeaderItem :focused="opened" @click="setOpened(!opened)">
      {{ 
        REGEX.HELP_VERSION.test(route.params.version)
          ? route.params.version
          : HELP.LATEST_VERSION 
      }}
    </TheHeaderItem>

    <TheHeaderItemList :visible="opened">
      <NavItem
        v-for="{ to, label } of options"
        :key="label"
        :to
        component="NuxtLink"
      >
        {{ label }}
      </NavItem>
    </TheHeaderItemList>
  </div>
</template>

<script setup lang="ts">
// components
import NavItem from "~/components/redesign/NavItem.vue";
import TheHeaderItem from "~/components/redesign/TheHeaderItem.vue";
import TheHeaderItemList from "~/components/redesign/TheHeaderItemList.vue";

// composable
import onRouteChange from "~/composables/onRouteChange";

// directives
import { vOnClickOutside } from "@vueuse/components";

// constants
import { HELP, REGEX } from "~/constants";

defineProps<{
  options: {
    to: string;
    label: string;
  }[];
}>();

const route = useRoute();

const opened = ref(false);
const setOpened = (value: boolean) => {
  opened.value = value;
};
const clickOutsideHandler = () => {
  if (opened.value) {
    setOpened(false);
  }
};

onRouteChange(() => setOpened(false));
</script>

<style scoped lang="scss">
@import "$/functions/token";
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/variables/shadows";

[data-component-name="LanguageSwitcherDesktop"] {
  position: relative;
  @include subtitle-3;

  user-select: none;

  [data-component-name="TheHeaderItem"] {
    text-transform: uppercase;
  }

  [data-component-name="TheHeaderItemList"] {
    width: 17.5rem;
  }
}
</style>
