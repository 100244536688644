<template>
  <div data-layout-name="default">
    <BreakingNewsBanner v-if="isPageMayHaveBanner" />

    <TheHeader />

    <main>
      <slot />
    </main>

    <TheFooter />
  </div>

  <ClientOnly>
    <Teleport to="#overlay">
      <transition name="fade" mode="out-in">
        <TheSearch v-if="isSearchVisible" @close="closeSearch" />
      </transition>

      <transition name="slide-from-right">
        <SidebarMobile v-if="isMobileMenuVisible" @close="closeMobileMenu" />
      </transition>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
// components
import TheHeader from '~/components/redesign/TheHeader.vue';
import SidebarMobile from '~/components/redesign/SidebarMobile.vue';
import TheSearch from '~/components/redesign/TheSearch';
import BreakingNewsBanner from '~/components/redesign/BreakingNewsBanner.vue';
import TheFooter from '~/components/redesign/TheFooter.vue';

// composables
import { storeToRefs } from 'pinia';
import onRouteChange from '~/composables/onRouteChange';

// store
import useUIStore from '~/stores/ui';
import { useVeeamOnStore } from '~/stores/veeamon';

const { isPageMayHaveBanner } = storeToRefs(useVeeamOnStore());

const uiStore = useUIStore();
const { setOverlay, setMobileMenuVisibility, setSearchVisibility } = uiStore;
const { isMobileMenuVisible, isSearchVisible } = storeToRefs(uiStore);

const closeMobileMenu = () => {
  setOverlay({ visible: false });
  setMobileMenuVisibility(false);
};

const closeSearch = () => {
  setOverlay({ visible: false });
  setSearchVisibility(false);
};

onRouteChange(closeMobileMenu);
</script>

<style lang="scss">
@import "$/mixins/media";
@import "$/mixins/size";
@import "$/functions/token";

[data-layout-name="default"] {
  padding-top: 7.5rem;

  @include mobile {
    padding-top: 7.0625rem;
  }

  [data-component-name="TheHeader"] {
    position: fixed;
    left: 0;
    top: 3.25rem;
    right: 0;
    z-index: 6;

    @include mobile {
      top: 2.6875rem;
    }
  }

  [data-component-name="BreakingNewsBanner"] {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 5;
  }
}

[data-component-name="SidebarMobile"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

[data-component-name="TheSearch"] {
  position: absolute;
  top: 5.5rem;

  @include fluid-max-width(75rem);
  max-height: calc(100dvh - (5.5rem + 1.5rem));

  @include tablet {
    @include fluid-max-width(calc(100% - 4rem));
  }

  @include mobile {
    top: 3.5rem;

    @include fluid-max-width(100%);
    max-height: calc(100dvh - (3.5rem + 1rem));
  }
}
</style>
