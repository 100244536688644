export const VIDEOS = {
  TESTIMONIAL_LILLIS_CDN: 'https://objectfirst.b-cdn.net/videos/Lillis%20Technology%20Group%20-%20YouTube%20-%204K.mov',
  TESTIMONIAL_LILLIS_YT: 'https://www.youtube.com/watch?v=Z2_bPKtIbVs',
  TESTIMONIAL_CENTERBASE_CDN: 'https://objectfirst.b-cdn.net/videos/Centerbase%20-%20YouTube%20-%204K.mov',
  TESTIMONIAL_CENTERBASE_YT: 'https://youtu.be/_AN4UuKmHwQ',

  ZERO_GRAVITY_ACE_JONAH_MAY_YT: 'https://www.youtube.com/watch?v=kFNwjOm7tU0&list=PLiLPnCJUXvPNEfMB41uHtAl7stSdo281g&index=2',
}

export const CALCULATOR = {
  VM: {
    DEV: 'https://v2testvm.calculator.objectfirst.com/',
    PROD: 'https://vm.calculator.objectfirst.com/',
  },
  NAS: {
    DEV: 'https://v2testnas.calculator.objectfirst.com/',
    PROD: 'https://nas.calculator.objectfirst.com/',
  },
};

export const PARTNERS = 'https://partners.objectfirst.com';
export const PARTNERS_LOGIN = `${PARTNERS}/login`;
